html,
body {
	margin: 0px;
	height: 100%;
}

.maintenance {
	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-columns: 15% 70% 15%;
	grid-template-rows: 15% 70% 15%;
}

.maintenance * {
	justify-self: center;
	align-self: center;
}

.maintenance-gif {
	grid-column: 2;
	grid-row: 2;
	width: auto;
	height: 80%;
}

.halt {
	grid-column: 2;
	grid-row: 1;
}

.halt-reason {
	grid-column: 2;
	grid-row: 3;
}

.siren1 {
	grid-column: 1;
	grid-row: 1;
	height: 100%;
	width: auto;
}

.siren2 {
	grid-column: 3;
	grid-row: 1;
	height: 100%;
	width: auto;
}

.connor1 {
	grid-column: 1;
	grid-row: 2;
	height: 85%;
	width: auto;
}

.connor2 {
	grid-column: 3;
	grid-row: 2;
	height: 85%;
	width: auto;
}
