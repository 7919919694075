/* language: css */

@import url("https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

img {
	max-width: 100%;
}

/* make the default font comic sans */
* {
	font-family: "Comic Sans MS", "Comic Sans", "Comic Neue", cursive,
		sans-serif;
}

/* style the header */
.header {
	background-color: #f1f1f1;
	padding: 30px;
	text-align: center;
}

/* style a navigation bar */
.navbar {
	overflow: hidden;
	background-color: #0047ab;
}
/* style the navigation bar links */
.navbar button {
	display: inline-block;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	background-color: #0047ab;
	height: 100%;
	width: 100%;
	border: none;
}
/* style the navigation bar links on hover */
.navbar button:hover {
	background-color: #ddd;
	color: black;
	cursor: pointer;
}
/* style the active link in the navigation bar */
/* .navbar a.active {
	background-color: #4caf50;
	color: white;
} */
/* style the navigation bar list items */
.navbar ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.navbar ul li {
	width: 100%;
	border-right: 1px black solid;
}

.navbar ul li:last-child {
	border-right: none;
}

/* create a grid layout with seven columnns of equal fluid width */
.navbar ul {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}

#root {
	display: grid;
	grid-template-columns: 20% 60% 20%;
}

.content {
	grid-column: 2;
	background-color: #f1f1f1;
}

.gut-grabber {
	grid-column: 3;
	width: 100%;
	height: 100vh;
	padding: 40px;
	position: sticky;
	top: 0px;
}

.gut-grabber img {
	width: 100%;
	height: 100%;
}

.snap-ad {
	grid-column: 1;
	width: 100%;
	height: 100vh;
	padding: 40px;
	position: sticky;
	top: 0px;
}

.snap-ad img {
	width: 100%;
	height: 100%;
}

.snap-ad .close-button {
	position: absolute;
	top: 42px;
	right: 42px;
	z-index: 1;
}

/* create a black 1px solid border on the sides of the root div */
.content {
	border-left: 1px solid black;
	border-right: 1px solid black;
}

.header h1 {
	font-size: 3em;
	font-weight: bold;
}

.intro,
.body,
.resize,
.chat,
.fitness,
.shorts {
	background-color: #f1f1f1;
	padding: 30px;
	text-align: left;
}

.intro p,
.body p,
.body ul {
	font-size: 18px;
	padding: 0px 0px;
}

.intro h2,
.body h2,
.fitness h2,
.shorts h2 {
	text-decoration: underline;
	padding: 15px 0px;
}

.resize {
	background-color: #f1f1f1;
	padding: 30px;
	text-align: left;
}

.resize h2 {
	text-decoration: underline;
}

.navbar .nav-link-active {
	background-color: #4caf50;
	color: white;
}

.navbar .nav-link-active:hover {
	background-color: #4caf50;
	color: white;
	cursor: default;
}

.body ul {
	margin-left: 53px;
}

.literature {
	margin-left: 23px;
}

.password {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.gallery-image {
	max-width: 100%;
	height: auto;
}

.chat {
	width: 100%;
	height: 600px;
}

.chat-window {
	width: 100%;
	height: 100%;
	background-color: #fff;
	display: flex;
	flex-direction: column;
}

.chat .input,
.chat form {
	width: 100%;
}

.chat form {
	display: flex;
	padding: 10px;
	border: 1px solid;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.chat .field1 {
	flex-grow: 1;
}

.chat .field1:focus {
	border: none;
	outline: none;
}

.chat input {
	border: none;
	padding: 5px;
}

.chat .message-submit {
	color: #fff;
	background-color: #1982fc;
	border-radius: 7px;
	padding: 8px 15px;
}

.chat .message-submit:hover {
	cursor: pointer;
}

.chat .screen {
	flex-grow: 1;
	width: 100%;
	border: 1px solid;
	border-bottom: none;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding: 15px;
	overflow-y: auto;
}

.chat form,
.chat form * {
	background-color: #f1f1f1;
}

.chat .imessage {
	display: flex;
	flex-direction: column;
	font-size: 1.25rem;
}

.chat .imessage p {
	border-radius: 1.15rem;
	line-height: 1.25;
	max-width: 75%;
	padding: 0.5rem 0.875rem;
	position: relative;
	word-wrap: break-word;
}

.chat .imessage p::before,
.chat .imessage p::after {
	bottom: -0.1rem;
	content: "";
	height: 1rem;
	position: absolute;
}

.chat p.from-me {
	align-self: flex-end;
	background-color: #248bf5;
	color: #fff;
}

.chat p.from-me::before {
	border-bottom-left-radius: 0.8rem 0.7rem;
	border-right: 1rem solid #248bf5;
	right: -0.35rem;
	transform: translate(0, -0.1rem);
}

.chat p.from-me::after {
	background-color: #fff;
	border-bottom-left-radius: 0.5rem;
	right: -40px;
	transform: translate(-30px, -2px);
	width: 10px;
}

.chat p[class^="from-"] {
	margin: 0.5rem 0;
	width: fit-content;
}

.chat p.from-me ~ p.from-me {
	margin: 0.25rem 0 0;
}

.chat p.from-me ~ p.from-me:not(:last-child) {
	margin: 0.25rem 0 0;
}

.chat p.from-me ~ p.from-me:last-child {
	margin-bottom: 0.5rem;
}

.chat p.from-them {
	align-items: flex-start;
	background-color: #e5e5ea;
	color: #000;
}

.chat p.from-them:before {
	border-bottom-right-radius: 0.8rem 0.7rem;
	border-left: 1rem solid #e5e5ea;
	left: -0.35rem;
	transform: translate(0, -0.1rem);
}

.chat p.from-them::after {
	background-color: #fff;
	border-bottom-right-radius: 0.5rem;
	left: 20px;
	transform: translate(-30px, -2px);
	width: 10px;
}

.chat p[class^="from-"].emoji {
	background: none;
	font-size: 2.5rem;
}

.chat p[class^="from-"].emoji::before {
	content: none;
}

.chat .no-tail::before {
	display: none;
}

.chat .margin-b_none {
	margin-bottom: 0 !important;
}

.chat .margin-b_one {
	margin-bottom: 1rem !important;
}

.chat .margin-t_one {
	margin-top: 1rem !important;
}

.chat ::-webkit-scrollbar {
	width: 20px;
}

.chat ::-webkit-scrollbar-track {
	z-index: -1;
	padding: 50px;
}

.chat ::-webkit-scrollbar-thumb {
	z-index: 1;
	border: 7px solid rgba(0, 0, 0, 0);
	border-radius: 9999px;
	background: rgba(0, 0, 0, 0.2);
	background-clip: padding-box;
}

.photos {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: nowrap;
}

.poem-container {
	display: grid;
	grid-template-columns: 80px 1fr;
}

.poem {
	grid-column-start: 2;
}

.video {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
	margin-bottom: 40px;
}

.video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.body .credits {
	font-size: 1rem;
	color: #999;
}

.photos button {
	background-color: #0047ab;
	border-radius: 7px;
	padding: 8px 15px;
	color: #fff;
	font-size: 1.25rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	cursor: pointer;
	border: none;
}

@media screen and (max-width: 768px) {
	#root {
		display: grid;
		grid-template-columns: 100%;
	}

	.header {
		display: none;
	}

	.snap-ad,
	.gut-grabber {
		height: auto;
		grid-column: 1;
		position: relative;
	}

	.content {
		position: relative;
		grid-column: 1;
	}
}
